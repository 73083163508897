import { initialize as LDInitialize, type LDClient, type LDFlagChangeset } from 'launchdarkly-js-client-sdk';
import { computed, reactive } from 'vue';

import { retryService } from '@exchange/libs/utils/retry/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import { featureFlagsDefaults, type FeatureFlags } from './defaults';

class LaunchDarkly {
  private readonly logPrefix = 'LaunchDarkly:';

  private readonly envKey = process.env.VUE_APP_LD_KEY;

  private ldClient: LDClient | undefined;

  private getLdClient = async () => {
    if (!this.envKey) {
      throw new Error(`${this.logPrefix} environment ID is not provided`);
    }

    if (this.ldClient) {
      return Promise.resolve(this.ldClient);
    }

    const ldClient = LDInitialize(this.envKey, { kind: 'user', key: 'anonymous', anonymous: true }, { fetchGoals: false });

    this.ldClient = ldClient;

    const getFlagsAnonymously = new Promise<void>((res) => {
      ldClient.on('ready', () => res());
      ldClient.on('error', (e) => {
        logger.error(`${this.logPrefix} client initializes failed`, e);
      });
      ldClient.on('failed', () => {
        logger.error(`${this.logPrefix} The client encountered an error that prevented it from connecting`);
      });
      ldClient.on('change', (changedFlags: LDFlagChangeset) => {
        nonProdConsoleInfo(`${this.logPrefix} live changes received`, changedFlags);
        this.updateFlags(changedFlags);
      });
    });

    await getFlagsAnonymously;

    return ldClient;
  };

  private flagsReady = {
    unauthorized: false,
    authorized: false,
  };

  private flagsReadyQueue = {
    unauthorized: new Array<(value: void | PromiseLike<void>) => void>(),
    authorized: new Array<(value: void | PromiseLike<void>) => void>(),
  };

  private featureFlags = reactive<FeatureFlags>({} as FeatureFlags);

  public flags = {
    'balances-chart-default-period': computed(() => this.featureFlags['balances-chart-default-period'] ?? featureFlagsDefaults['balances-chart-default-period']),
    'capacitor-updater': computed(() => this.featureFlags['capacitor-updater'] ?? featureFlagsDefaults['capacitor-updater']),
    'featured-fiat-currencies': computed(() => this.featureFlags['featured-fiat-currencies'] ?? featureFlagsDefaults['featured-fiat-currencies']),
    'is-futures-page-enabled': computed(() => this.featureFlags['is-futures-page-enabled'] ?? featureFlagsDefaults['is-futures-page-enabled']),
    'is-card-payment-enabled': computed(() => this.featureFlags['is-card-payment-enabled'] ?? featureFlagsDefaults['is-card-payment-enabled']),
    'market-orders': computed(() => this.featureFlags['market-orders'] ?? featureFlagsDefaults['market-orders']),
    'my-orders-open-limit': computed(() => this.featureFlags['my-orders-open-limit'] ?? featureFlagsDefaults['my-orders-open-limit']),
    'orderbook-fps': computed(() => this.featureFlags['orderbook-fps'] ?? featureFlagsDefaults['orderbook-fps']),
    'orderbook-tracing': computed(() => this.featureFlags['orderbook-tracing'] ?? featureFlagsDefaults['orderbook-tracing']),
    'partner-promo-notifications': computed(() => this.featureFlags['partner-promo-notifications'] ?? featureFlagsDefaults['partner-promo-notifications']),
    'platform-upgrade': computed(() => this.featureFlags['platform-upgrade'] ?? featureFlagsDefaults['platform-upgrade']),
    'platform-upgrade-prep': computed(() => this.featureFlags['platform-upgrade-prep'] ?? featureFlagsDefaults['platform-upgrade-prep']),
    'throttle-ws': computed(() => this.featureFlags['throttle-ws'] ?? featureFlagsDefaults['throttle-ws']),
    'trending-markets': computed(() => this.featureFlags['trending-markets'] ?? featureFlagsDefaults['trending-markets']),
    'uk-fca': computed(() => this.featureFlags['uk-fca'] ?? featureFlagsDefaults['uk-fca']),
    getChartingLibraryEnableGoTo: () => this.featureFlags['charting-library-go-to-date'] ?? featureFlagsDefaults['charting-library-go-to-date'],
    getLogging: () => this.featureFlags.feLogging ?? featureFlagsDefaults.feLogging,
    getPlatformUpgrade: () => this.featureFlags['platform-upgrade'] ?? featureFlagsDefaults['platform-upgrade'],
    getPlatformUpgradePrep: () => this.featureFlags['platform-upgrade-prep'] ?? featureFlagsDefaults['platform-upgrade-prep'],
    getSettlementDelay: () => this.featureFlags['settlement-delay'] ?? featureFlagsDefaults['settlement-delay'],
    kryptview: computed(() => this.featureFlags.kryptview ?? featureFlagsDefaults.kryptview),
    verification: computed(() => this.featureFlags.verification ?? featureFlagsDefaults.verification),
  };

  private setFlag = (key, value) => {
    this.featureFlags[key] = value;
  };

  private updateFlags = (changedFlags: LDFlagChangeset) => {
    Object.keys(changedFlags).forEach((key) => {
      this.setFlag(key, changedFlags[key]?.current);
    });
  };

  private onReady(unauthorized: boolean) {
    return new Promise<void>((res) => {
      if (unauthorized) {
        if (this.flagsReady.unauthorized) {
          res();
          return;
        }

        this.flagsReadyQueue.unauthorized.push(res);
      } else {
        if (this.flagsReady.authorized) {
          res();
          return;
        }

        this.flagsReadyQueue.authorized.push(res);
      }
    });
  }

  public async getWhenReady<T extends keyof FeatureFlags>(name: T, { unauthorized } = { unauthorized: true }): Promise<FeatureFlags[T]> {
    await this.onReady(unauthorized);

    return this.featureFlags[name] ?? featureFlagsDefaults[name];
  }

  /**
   * To be called when LD user needs to be changed
   * @param accountPid If provided will be used to get flags for specific user
   */
  public identify = async (accountPid?: string) => {
    const flags = await this.getFeatureFlags(accountPid);

    Object.keys(flags).forEach((key) => {
      this.setFlag(key, flags[key]);
    });

    if (accountPid) {
      this.flagsReady.authorized = true;
      this.flagsReadyQueue.authorized.forEach((res) => res());
    } else {
      this.flagsReady.unauthorized = true;
      this.flagsReadyQueue.unauthorized.forEach((res) => res());
    }
  };

  private getFeatureFlags = async (accountId?: string): Promise<FeatureFlags> => {
    const ldClient = await this.getLdClient();

    const getAndProcessAllFlags = () => ldClient.allFlags() as FeatureFlags;

    if (!accountId) {
      return getAndProcessAllFlags();
    }

    try {
      await ldClient.identify({
        kind: 'user',
        key: accountId,
      });

      return getAndProcessAllFlags();
    } catch (e) {
      logger.error(`${this.logPrefix} failed to identify user and set launch-darkly feature flags:`, e);

      await retryService.waitForNextRetryTick();

      return this.getFeatureFlags(accountId);
    }
  };
}

export default new LaunchDarkly();
